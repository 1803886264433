import { createAsync } from '@solidjs/router';
import { For, Show, Suspense } from 'solid-js';
import { Button, Link, Container, Page, Section, Heading } from '@troon/ui';
import { Title } from '@solidjs/meta';
import { gql } from '../graphql';
import { ReservationCard } from '../components/reservation-card';
import { PageHeader } from '../components/page-header';
import { cachedQuery } from '../graphql/cached-get';
import type { ReservationFragment } from '../graphql';
import type { RouteDefinition } from '@solidjs/router';

export default function Reservations() {
	const data = createAsync(() => getReservations({}), { deferStream: true });
	return (
		<Container>
			<Page>
				<Title>Reservations | Troon</Title>
				<Section>
					<div class="border-b border-neutral-300 pb-8 md:pb-12">
						<PageHeader title={<>Reservations</>} subtext={<p>View and manage all of your tee times.</p>}>
							<Button appearance="primary" as={Link} href="/" class="md:shrink md:grow-0">
								Book a tee time
							</Button>
						</PageHeader>
					</div>

					<Heading as="h2">Upcoming reservations</Heading>
					<ul class="flex flex-col gap-y-8">
						<Suspense>
							<Show when={data()}>
								<For
									each={data()?.upcoming}
									fallback={
										<li>
											<Button as={Link} href="/">
												Book tee times now
											</Button>
										</li>
									}
								>
									{(res) => (
										<li>
											<ReservationCard {...(res as ReservationFragment)} />
										</li>
									)}
								</For>
							</Show>
						</Suspense>
					</ul>
				</Section>

				<Section>
					<Heading as="h2">Past reservations</Heading>
					<ul class="flex flex-col gap-y-8">
						<Suspense>
							<Show when={data()}>
								<For each={data()?.past} fallback={<li>No previous reservations</li>}>
									{(res) => (
										<li>
											<ReservationCard {...(res as ReservationFragment)} />
										</li>
									)}
								</For>
							</Show>
						</Suspense>
					</ul>
				</Section>
			</Page>
		</Container>
	);
}

export const route = {
	preload: () => getReservations({}),
} satisfies RouteDefinition;

const reservationsQuery = gql(`
query allReservations {
	upcoming: allReservations(upcoming: true, past: false) {
		...Reservation
	}
	past: allReservations(upcoming: false, past: true, sortOrder: DESC) {
		...Reservation
	}
}`);

const getReservations = cachedQuery(reservationsQuery);
